<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :left-text="$t('我的机器人')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="robot-wrapper" style="padding-top: unset">
            <div style="background: #1a2331;position: fixed;left:0;top:auto;width: 100%; padding: 10px 15px 15px 15px;z-index: 999;border-radius:0 0 20px 20px">
                <div class="filter-bar flex-center">
                    <div class="filter-item" @click="active = 0" :class="active == 0?'active':''">
                        <!-- 下拉 -->
                        <van-popover placement="bottom-start" v-model="showPopover" trigger="click">
                            <div class="filter-dropdown">
                                <van-radio-group v-model="status" @change="dItmeChange">
                                    <van-cell-group>
                                        <van-cell :title="$t('全部')" clickable @click="status = '-1'">
                                            <template #right-icon>
                                                <van-radio name="-1" checked-color="#20b759"/>
                                            </template>
                                        </van-cell>
                                        <van-cell :title="$t('未激活')" clickable @click="status = '0'">
                                            <template #right-icon>
                                                <van-radio name="0" checked-color="#20b759"/>
                                            </template>
                                        </van-cell>
                                        <van-cell :title="$t('已激活')" clickable @click="status = '1'">
                                            <template #right-icon>
                                                <van-radio name="1" checked-color="#20b759"/>
                                            </template>
                                        </van-cell>
                                        <van-cell :title="$t('暂停中')" clickable @click="status = '2'">
                                            <template #right-icon>
                                                <van-radio name="2" checked-color="#20b759"/>
                                            </template>
                                        </van-cell>
                                        <van-cell :title="$t('已过期')" clickable @click="status = '3'">
                                            <template #right-icon>
                                                <van-radio name="3" checked-color="#20b759"/>
                                            </template>
                                        </van-cell>
                                    </van-cell-group>
                                </van-radio-group>
                            </div>
                            <template #reference>
                                <div class="btn btn-blue">
                                    <span>{{$t('我的机器人')}}</span><span class="iconfont icon-down"></span>
                                </div>
                            </template>
                        </van-popover>
                    </div>
                    <div class="filter-item u-m-l20" @click="active = 1" :class="active == 1?'active':''">{{$t('已售')}}</div>
                </div>
            </div>
            <div class="page-main" v-if="active == 0">
                <template v-for="(item, index) in list">
                    <div class="page-robot-list" :key="index" style="margin-bottom: 15px;">
                        <div class="robot-card">
                            <div class="robot-card_hd flex-center" :class="item.status == 1?'robot-card_hd-ing':''">
                                <div class="robot-top-bg"></div>
                                <div class="robot-button">
                                    <img class="ro-logo" src="../../assets/pictures/avatar-default.png">
                                    <div class="tt">{{$t(item.robot.name)}}</div>
                                </div>
                                <template v-if="item.robot.type == 0">
                                    <div class="status" :class="item.status == '3' || item.status == '5' ?'text-yellow':''">
                                        <span class="btn btn-contact" v-if="item.status == 1 && item.r_count > 0"> {{$t('工作中')}}</span>
                                        <span class="btn btn-contact" v-else-if="item.status == 1 && item.r_count == 0">{{$t('连线中')}}</span>
                                        <span class="btn" v-else-if="item.status == 5">{{$t('出售待确认中')}}</span>
                                        <span class="btn btn-blue" v-else-if="item.status == 3">{{$t('已过期')}}</span>
                                        <span class="btn " v-else-if="item.status == 2">{{$t('暂停中')}}</span>
                                        <span class="btn" v-else>{{$t('未激活')}}</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="status">
                                        <span v-if="item.status == 1"> {{$t('工作中')}}</span>
                                        <span v-else-if="item.status == 6">{{$t('赎回申请中')}}</span>
                                        <span v-else-if="item.status == 3">{{$t('已过期')}}</span>
                                        <span v-else-if="item.status == 7">{{$t('已赎回')}}</span>
                                        <span v-else>{{$t('未激活')}}</span>
                                    </div>
                                </template>
                            </div>
                            <div class="rbody">
                                <template v-if="item.robot.type == 0">
                                    <div class="robot-items ">
                                        <div class="item" v-if="item.robot.level != 0 && item.status == 1">
                                            <span class="t">{{$t('冻结本金')}}: </span>
                                            <span class="text-primary">{{parseFloat(item.min_money).toFixed(2)}}</span>
                                            <span style="font-size: 12px"> {{$t('USDT')}}</span>
                                        </div>
                                        <div class="item" v-if="item.robot.level == 0" style="color: yellowgreen">
                                            <span class="t">{{$t('冻结本金')}}: </span>
                                            <span class="text-primary">{{parseFloat(item.min_money).toFixed(2)}}</span>
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('来源')}}: </span>
                                            <span class="text-primary">
                                            <template v-if="item.source == 0">{{$t('签约购买')}}</template>
                                            <template v-else-if="item.source == 1">{{$t('出售/赠送')}}</template>
                                            <template v-else-if="item.source == 2">{{$t('系统赠送')}}</template>
                                            <template v-else-if="item.source == 3">{{$t('邀请奖励赠送')}}</template>
                                            <template v-else-if="item.source == 5">{{$t('签到赠送')}}</template>
                                        </span>
                                        </div>
                                        <div class="item"><span class="t">
                                            {{$t('价格')}}：</span>
                                            <span class="text-red">{{item.price}} </span>
                                            <span class="text-red" style="font-size: 12px"> {{$t('USDT')}}</span>
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('有效期')}}：</span>
                                            <span class="text-primary">{{item.days}}</span>
                                            {{$t('天')}}
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('做单价格')}}：</span>
                                            <span class="text-red">{{parseFloat(item.min_money).toFixed(2)}} {{$t('元/单')}}</span>
                                            
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('获得时间')}}：</span>
                                            {{item.created_at}}
                                        </div>
                                        <div class="item" v-if="item.first_actived_at">
                                            <span class="t">{{$t('最后激活时间')}}：</span> {{item.first_actived_at}}
                                        </div>
                                        <div class="item" v-if="item.first_actived_at && item.use_time">
                                            <span class="t">{{$t('已运行时间')}}：</span>
                                            <span class="text-primary">{{Math.floor(item.use_time / 86400)}}</span>
                                            {{$t('天')}}
                                            <span class="text-primary">{{Math.floor(item.use_time % 86400 / 3600)}}</span>
                                            {{$t('小时')}}
                                            <span class="text-primary">{{Math.floor(item.use_time % 86400 % 3600 / 60)}}</span>
                                            {{$t('分')}}

                                        </div>
                                        <div class="item" v-if="item.form_user">
                                            <span class="t">{{$t('出售/赠送人')}}：</span> {{item.form_user}}
                                        </div>
                                        <div class="item"><span class="t">{{$t('唯一码')}}：</span>
                                            <span class="text-primary">{{item.password}}</span>
                                        </div>
                                    </div>
                                   <van-button type="info" round size="small"
                                               v-if="item.status == 0 && item.robot.level != 0"
                                               @click="sellShow(item)">{{$t('出售/赠送')}}
                                   </van-button>
                                    <van-button type="danger" round size="small"
                                                v-if="item.status == 0 || item.status == 2"
                                                @click="showActive(item)">{{$t('激活')}}
                                    </van-button>
                                    <van-button type="danger" round size="small"
                                                v-if="item.status == 1"
                                                @click="showStop(item)">
                                        {{$t('暂停')}}
                                    </van-button>
                                    <van-button type="warning" round size="small"
                                               v-if="item.status == 5"
                                               @click="onSellRet(item)">
                                       {{$t('回撤出售申请')}}
                                   </van-button>
                                </template>

                                <template v-else>
                                    <div class="robot-items ">
                                        <div class="item">
                                            <span class="t">{{$t('运营资金')}}: </span>
                                            <span class="text-primary">{{parseFloat(parseFloat(parseFloat(item.min_money) + parseFloat(item.profit)).toFixed(6))}}</span>
                                            <span style="font-size: 12px"> {{$t('USDT')}}</span>
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('当前收益')}}: </span>
                                            <span class="text-primary">{{parseFloat(item.profit)}}</span>
                                            <span style="font-size: 12px"> {{$t('USDT')}}</span>
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('累计收益')}}: </span>
                                            <span class="text-primary">{{parseFloat(item.profit_total)}}</span>
                                            <span style="font-size: 12px"> {{$t('USDT')}}</span>
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('周期')}}：</span>
                                            <span class="text-primary">{{item.robot.days}}</span>
                                            {{$t('天')}}
                                        </div>
                                        <div class="item">
                                            <span class="t">{{$t('开始时间')}}：</span>
                                            {{item.created_at}}
                                        </div>
                                        <div class="item" v-if="item.first_actived_at && item.use_time">
                                            <span class="t">{{$t('已运行时间')}}：</span>
                                            <span class="text-primary">{{Math.floor(item.use_time / 86400)}}</span>
                                            {{$t('天')}}
                                            <span class="text-primary">{{Math.floor(item.use_time % 86400 / 3600)}}</span>
                                            {{$t('小时')}}
                                            <span class="text-primary">{{Math.floor(item.use_time % 86400 % 3600 / 60)}}</span>
                                            {{$t('分')}}
                                        </div>
                                        <div class="item"><span class="t">{{$t('唯一码')}}：</span>
                                            <span class="text-primary">{{item.password}}</span>
                                        </div>
                                        <div class="item"><span class="t">{{$t('模式')}}：</span>
                                            <span class="text-primary">{{item.robot.is_stop == 1 ? $t('不可赎回') : $t('可赎回')}}</span>
                                        </div>
                                    </div>
                                    <van-button type="warning" round size="small"
                                                v-if="item.status == 1 && item.robot.is_stop == 0"
                                                @click="showStop(item)">
                                        {{$t('申请赎回')}}
                                    </van-button>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <div class="page-main" v-if="active == 1">
                <template v-for="(item, index) in list">
                    <div class="page-robot-list" :key="index" style="margin-bottom: 15px;">
                        <div class="robot-card">
                            <div class="robot-card_hd flex-center">
                                <div class="robot-button">
                                    <img class="ro-logo" src="../../assets/pictures/avatar-default.png">
                                    <div class="tt">{{$t(item.robot.name)}}</div>
                                </div>
                            </div>
                            <div class="rbody">
                                <div class="robot-items">
                                    <div class="item"><span class="t">{{$t('价格')}}：</span>{{item.robot.price}}
                                        <span
                                                style="font-size: 12px">{{$t('USDT')}}</span></div>
                                    <div class="item"><span class="t">{{$t('购买时间')}}：</span>{{item.created_at}}
                                    </div>
                                    <div class="item"><span class="t">{{$t('出售/赠送时间')}}：</span>{{item.updated_at}}
                                    </div>
                                    <div class="item"><span class="t">{{$t('出售/赠送价格')}}：</span> {{item.sell_price}}
                                        <span
                                                style="font-size: 12px">{{$t('USDT')}}</span>
                                    </div>
                                    <div class="item"><span class="t">{{$t('唯一码')}}：</span>{{item.password}}
                                    </div>
                                    <div class="item"><span class="t">{{$t('对方账户名称')}}：</span>{{item.form_user}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <van-popup class="bs-popup"
                   v-model="show" position="bottom" closeable>
            <!-- <div class="robot-top animated bounceInUp del3">
                <img class="ro-logo" src="../../assets/pictures/avatar-default.png">
            </div> -->
            <div class="bs-popup-dialog" v-if="sellInfo.robot">
                <div class="bs-popup-head">
                    <div class="flex-center">
                        <img class="ro-logo" src="../../assets/pictures/avatar-default.png">
                        <div class="tt">{{$t(sellInfo.robot.name)}}</div>
                    </div>
                </div>
                <div class="dialog-v-body">
                    <div class="bs-panel dv-card">
                        <div class="dv-row">
                            <div class="dv-data">
                                <div class="label">{{$t('唯一码')}}</div>
                                <div class="val">{{sellInfo.password}}</div>
                            </div>
                        </div>
                        <div class="dv-row">
                            <div class="dv-data">
                                <div class="label">{{$t('官方价格')}}</div>
                                <div class="val">{{sellInfo.robot.price}}</div>
                            </div>
                            <div class="dv-data" style="width: 50%">
                                <div class="label">{{$t('出售价值')}}</div>
                                <div class="val">{{money}}</div>
                            </div>
                        </div>
                        <div class="dv-row">
                            <div class="dv-data" style="width: 100%;text-align: center">
                                <div style="display: flex;justify-content: space-between;align-items: center;align-content: center;">
                                    <div class="ac-box">
                                        <van-icon name="minus" size="14" @click="minus"/>
                                    </div>
                                    <div style="width: 100%;padding: 0 8px">
                                        <van-slider
                                                active-color="#3653ea"
                                                class="s-slider"
                                                v-model="money" min="0" :max="sellInfo.robot.price" step="1"
                                        >
                                            <template #button>
                                                <div class="s-btn">
                                                    {{ money }}
                                                </div>
                                            </template>
                                        </van-slider>
                                    </div>
                                    <div class="ac-box">
                                        <van-icon name="plus" size="14" @click="plus"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bs-panel dv-card">
                        <div class="dv-input-row">
                            <van-field v-model="username" :placeholder="$t('请输入对方手机号码')"/>
                        </div>
                        <div class="dv-less">
                            <div class="label text-gray">
                                {{$t('转入财产请仔细审核信息，如操作失误，无法通过系统或平台客服找回。')}}
                            </div>
                        </div>
                        <div class="dv-foot">
                            <van-button @click="onSell" color="linear-gradient(to right, #3270e0, #3653ea)">{{$t('确认')}}</van-button>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup class="bank-popup" v-model="show_stop" position="bottom">
            <div style="padding: 10px 20px 30px 20px;line-height: 20px;color: #000;font-size: 12px !important;">
                <div class="tips" style="font-weight: bold;color: #999999;padding-bottom: 10px">{{$t('温馨提示')}}</div>
                <div class="tips">{{$t('您当前运营资金')}}:
                    <span style="color: #0184fa;font-weight: bold">{{parseFloat(parseFloat(stop_item.min_money) + parseFloat(stop_item.profit))}}</span>
                    <span> {{$t('USDT')}}</span>
                </div>
                <div class="tips">{{$t('预估日收益可达')}}:
                    <span style="color: #0184fa;font-weight: bold"> {{stop_item.r}}</span>
                    <span> {{$t('USDT')}}</span>
                </div>
                <div class="tips">{{$t('手续费')}}:
                    <span style="color: #0184fa;font-weight: bold" v-if="stop_item.robot">{{parseFloat(stop_item.robot.fee * 100).toFixed(2)}}%</span>
                </div>
                <div class="tips" style="padding-top: 10px">{{$t('申请赎回将无法获得收益，并扣除相应本金作为手续费，确认要申请赎回吗？')}}</div>
            </div>
            <div style="padding: 20px;display: flex;justify-content: space-between">
                <div style="width: 49%">
                    <van-button class="btn" color="#ccc" block @click="onStop2(stop_item)" :disabled="btn_flag">
                        {{$t('申请赎回')}}
                    </van-button>
                </div>
                <div style="width: 49%">
                    <van-button class="btn" color="#0184fa" block @click="show_stop = false">{{$t('取消')}}</van-button>
                </div>
            </div>
        </van-popup>
        <van-popup class="bank-popup" v-model="active_show" closeable position="center">
           <div class="active-panel_body">
            <div class="bs-panel_tt">
                {{$t('激活')}}
            </div>
            <div class="active-content" v-if="activeShowInfo">
                <div class="b-row">
                    <div class="b-row-label">{{$t('预计每日收益')}}</div>
                    <div class="b-row-val">
                       {{activeMoney}} USDT
                    </div>
                </div>
                <div class="b-row">
                    <div class="b-row-label">{{$t('有效期')}}</div>
                    <div class="b-row-val">
                        {{ activeShowInfo.days }} {{$t('天')}}
                    </div>
                </div>
                <div class="b-row">
                    <div class="b-row-label">{{$t('请输入做单金额')}}</div>
                    <div class="b-row-val">
                        {{parseFloat(activeShowInfo.robot.min_money).toFixed(2)}}-{{parseFloat(activeShowInfo.robot.max_money).toFixed(2)}} USDT
                    </div>
                </div>
            </div>
            <div class="active-foot">
                <div class="active-input-row" >
                    <van-field v-model="activeValue" @input="activeChange" :placeholder="$t('请输入做单金额')"/>
                    <div class="b-row-label">
                        <span>{{$t('当前余额')}}：</span>
                        <span >
                            {{parseFloat(user.balance - this.activeValue).toFixed(6) }}
                        </span>
                    </div>
                    <div class="b-row-label">
                        <span >
                            {{parseFloat(user.balance ).toFixed(6) }}
                        </span>
                    </div>
                </div>
                <van-button type="info"  @click="onActive">
                    {{ $t('确定') }}
                </van-button>
            </div>
           </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                btn_flag: false,
                fee: 0,
                money: 0,
                username: '',
                show: false,
                show_stop: false,
                showPopover: false,
                stop_item: {},
                active: '',
                status: '-1',
                list: [],
                sellInfo: {},
                options: [],
                active_show:false,
                activeValue:750,
                activeMoney:0,
                activeShowInfo: null,
                minVal: 1,
                maxVal: 1,
                user:{}
            }
        },
        methods: {
            minus() {
                if (this.money >= 1) {
                    this.money = this.money - 1;
                }
            },
            plus() {
                if (this.money + 1 <= this.sellInfo.robot.price) {
                    this.money = this.money + 1;
                }
            },
            setClass(value) {
                let obj = {face: true}
                obj["ro-style-" + `${value % 4}`] = true
                return obj
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            onTabChange(val) {
                if (val == 0) {
                    this.status = '-1';
                } else {
                    this.status = '4';
                }
                this.getData();
            },
            sellShow(item) {
                this.username = '';
                this.sellInfo = item;
                this.money = parseInt(item.robot.price / 2);
                this.show = true;
            },
            getData() {
                this.list = [];
                let params = '';
                if (this.status >= 0) {
                    params = '?status=' + this.status;
                }
                this.$axios.get('/robot/my_robots' + params)
                    .then((res) => {
                        this.list = res.data.data;
                        console.log(this.list);
                    });
            },
            onSell() {
                if (this.username == '') {
                    this.$toast.success(this.$t('请填写对方账户名称'));
                }
                this.$axios.post('/robot/sell_robot', {
                    id: this.sellInfo.id,
                    username: this.username,
                    money: this.money
                }).then((res) => {
                    if (res.data.code == 0) {
                        this.show = false;
                        this.$toast.success(res.data.msg);
                        this.getData();
                    } else {
                        this.$toast.fail(res.data.msg);
                    }
                }).catch((res) => {
                    this.$toast.fail(res.data.msg);
                });
            },
            onSellRet(item) {
                this.$axios.post('/robot/sell_ret', {id: item.id})
                    .then((res) => {
                        this.$toast.success(res.data.msg);
                        this.getData();
                    });
            },
            showActive(item){
                this.active_show = true;
                this.activeShowInfo = item
                this.activeValue = Math.ceil((parseFloat(item.robot.min_money) + parseFloat(item.robot.max_money)) / 2)
                this.maxVal = Math.ceil(parseFloat(item.robot.max_money))
                this.minVal = Math.ceil(parseFloat(item.robot.min_money))
                this.activeChange()
            },
            activeChange(value){
                const inputValue = this.activeValue === '' ? 0 : parseFloat(this.activeValue);
                if (!isNaN(inputValue) && inputValue > parseFloat(this.user.balance)) {
                    // 如果输入值大于余额，将输入值设置为余额
                    this.activeValue = parseFloat(this.user.balance).toFixed(6);
                    this.activeMoney = parseFloat(this.activeShowInfo.r2 * this.activeValue).toFixed(6)
                }else{
                    this.activeMoney = parseFloat(this.activeShowInfo.r2 * this.activeValue).toFixed(6)
                }
               
            },
            onActive() {
                if (this.activeValue > this.maxVal) {
                    this.$toast(this.$t('激活金额不能大于') + this.maxVal + this.$t('元'));
                    return;
                }
                if (this.activeValue < this.minVal) {
                    this.$toast(this.$t('激活金额不能小于') + this.minVal + this.$t('元'));
                    return;
                }
                this.$axios.post('/robot/activate', {
                    id: this.activeShowInfo.id,
                    money: this.activeValue
                    })
                    .then((res) => {
                        this.$toast.success(res.data.msg);
                        this.getData();
                        this.active_show = false
                    });
            },
            showStop(item) {
                if (item.robot.type == 1) {
                    this.stop_item = item;
                    this.show_stop = true;
                } else {
                    this.onStop(item);
                }
            },
            onStop(item) {
                this.btn_flag = true;
                this.$axios.post('/robot/stop', {id: item.id})
                    .then((res) => {
                        this.$toast.success(res.data.msg);
                        this.getData();
                        this.show_stop = false;
                        this.btn_flag = false;
                    });
            },
            onStop2(item) {
                this.btn_flag = true;
                this.$axios.post('/robot/stop2', {id: item.id})
                    .then((res) => {
                        this.$toast.success(res.data.msg);
                        this.getData();
                        this.show_stop = false;
                        this.btn_flag = false;
                    });
            },
            dItmeChange(value) {
                this.status = value;
                this.getData();
            },
            getConfig() {
                this.$axios
                    .get(this.$api_url.get_home_data)
                    .then((res) => {
                            if (res.data && res.data.code == 0) {
                                this.user = res.data.data.user;
                                console.log(this.user, 'user');
                            }
                        }
                    )
                    .catch((err) => {
                        console.log(err.message);
                        // this.$toast.fail(err.message);
                    });
            },
        },
        mounted() {
            this.getData();
            this.getConfig();
        },
        created() {
            if (this.$route.query.status != undefined) {
                this.status = this.$route.query.status;
            }
            this.options = [
                {text: this.$t('全部'), value: -1},
                {text: this.$t('未激活'), value: 0},
                {text: this.$t('已激活'), value: 1},
                {text: this.$t('暂停中'), value: 2},
                {text: this.$t('已过期'), value: 3},
            ];
        },
        watch: {
            active(nv, ov) {
                if (nv != ov) {
                    this.onTabChange(nv);
                }
            }
        }
    }
</script>
<style scoped>
    .page ::v-deep .van-nav-bar{
        background-color: #1a2331;
    }
    .filter-item{
        font-size: 14px;
    }
    .page-main{
        padding-top: 70px;
    }
    .filter-bar{
        display: flex;
    }
    .s-slider {
        height: 10px;
        background-color: #000;
    }
    .robot-card{
        padding: 15px;
        font-size: 14px;
        border-radius: 10px;
        overflow: hidden;
        background-color: #1a2331;
    }
    .s-btn{
        text-shadow: 0 0 4px #000;
    }
    .ac-box{
        background-color: #3653ea;
        width: 20px;
        min-width: 20px;
        height: 20px;
        border-radius: 20px;
        color: #000;
        font-weight: bold;
    }
    .robot-card_hd{
        margin-bottom: 10px;
    }
    .robot-card_hd .status{
        margin-left: auto;
    }
    .status .btn{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        min-width: 90px;
        border-radius: 90px;
        background-color: #252f46;
        border: 1px solid #ffffff;
        color: #ffffff;
    }

    .status .btn-old{
        border: 1px solid #ee4646;
        color: #ee4646;
    }
    .status .btn-contact{
        border: 1px solid #3270e1;
        color: #3270e1;
    }
    .status .btn-ing{
        border: 1px solid #151515;
        background-color: #3653ea;
        color: #ffffff;

    }
    .rbody .item .t{
        opacity: .6;
    }
    .rbody .item{
        padding: 5px 0;
    }
    .robot-button{
        position: relative;
        display: flex;
        align-items: center;
        background-color: #252f46;
        height: 40px;
        min-width: 140px;
        border-radius: 40px;
    }
    .robot-button::after{
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: #3653ea;
        content: '';
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -2px;
    }
    .robot-button .ro-logo{
        width: 42px;
        height: 42px;
        margin-right: 10px;
        margin-left: -8px;
    }
    .robot-items{
        margin-bottom: 10px;
    }
    .rbody .van-button{
        min-width: 60px;
        margin-right: 10px;
    }
    .btn-black .iconfont{
        opacity: .6;
        margin-left: 5px;
    }
    .ro-logo {
        width: 42px;
        height: 42px;
        margin-right: 10px;
    }
    .dv-input-row ::v-deep .van-field__control{
        color: #ffffff;
    }
    .active-panel_body{
        min-width: 340px;
        padding: 20px;
        font-size: 16px;
    }
    .active-panel_body .bs-panel_tt{
        text-align: center;
        font-size: 20px;
        margin-bottom: 15px;
    }
    .active-panel_body .b-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    .b-row-label{
        color: #7670ad;
    }
    .active-foot{
        text-align: center;
    }
    .active-foot .van-button{
        width: 60%;
    }
    .active-foot .dv-input-row{
        display: flex;
        align-items: center;
        padding: 15px 0;
        margin-bottom: 10px;
    }
    .dv-input-row .b-row-label{
        margin-left: 10px;
    }
    .dv-input-row ::v-deep .van-stepper__input{
        width: 80px;
    }


</style>